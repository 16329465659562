import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Layout from "@/layout/index.vue"

Vue.use(VueRouter)

const routes = [ 
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/',
    name: 'Layout',
    redirect:'/index',
    component: Layout
  },
  {
    path: '/index',
    name: 'index',
    component: Layout,
    redirect: '/index/lineChart',
    meta: {title: 'home', icon: 'user'},
    children: [
      //在线折线图表
      {
        path: 'lineChart',
        name: 'lineChart',
        component: () => import('@/views/index/online/index'),
        meta: {title: '在线折线图', icon: 'list'}
      },
      //在线数据占比
      {
        path: 'dataPersent',
        name: 'dataPersent',
        component: () => import('@/views/index/online/datapersent'),
        meta: {title: '在线数据占比', icon: 'list'}
      },
    ]
  },
  {
    path: '/appversion',
    name: 'appversion',
    component: Layout,
    redirect: '/appversion/appversionList',
    meta: {title: '用户', icon: 'user'},
    children: [
      //管理列表
      {
        path: 'appversionList',
        name: 'appversionList',
        component: () => import('@/views/appversion/list/index'),
        meta: {title: '管理列表', icon: 'list'}
      },
      {
        path: 'updateAppversion',
        name: 'updateAppversion',
        component: () => import('@/views/appversion/list/update'),
        meta: {title: '管理修改', icon: 'list'}
      },
      {
        path: 'addAppversion',
        name: 'addAppversion',
        component: () => import('@/views/appversion/list/add'),
        meta: {title: '创建管理', icon: 'list'}
      },
      {
        path: 'suggestions',
        name: 'suggestions',
        component: () => import('@/views/appversion/suggestions/index'),
        meta: {title: '创建管理', icon: 'list'}
      },
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: Layout,
    redirect: '/admin/adminList',
    meta: {title: '用户', icon: 'user'},
    children: [
      //管理列表
      {
        path: 'adminList',
        name: 'adminList',
        component: () => import('@/views/admin/list/index'),
        meta: {title: '管理列表', icon: 'list'}
      },
      {
        path: 'updateAdmin',
        name: 'updateAdmin',
        component: () => import('@/views/admin/list/update'),
        meta: {title: '管理修改', icon: 'list'}
      },
      {
        path: 'addAdmin',
        name: 'addAdmin',
        component: () => import('@/views/admin/list/add'),
        meta: {title: '创建管理', icon: 'list'}
      },
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: Layout,
    redirect: '/user/userList',
    meta: {title: '用户', icon: 'user'},
    children: [
      //用户列表
      {
        path: 'userList',
        name: 'userList',
        component: () => import('@/views/user/list/index'),
        meta: {title: '用户列表', icon: 'list'}
      },
      {
        path: 'updateUser',
        name: 'updateUser',
        component: () => import('@/views/user/list/update'),
        meta: {title: '用户修改', icon: 'list'}
      },
    ]
  },
  {
    path: '/sounds',
    name: 'sounds',
    component: Layout,
    redirect: '/sounds/soundsList',
    meta: {title: '音效', icon: 'sounds'},
    children: [
      //音效列表
      {
        path: 'soundsList',
        name: 'soundsList',
        component: () => import('@/views/sounds/list/index'),
        meta: {title: '音效列表', icon: 'list'}
      },
      {
        path: 'updateSound',
        name: 'updateSound',
        component: () => import('@/views/sounds/list/update'),
        meta: {title: '修改音效', icon: 'list'}
      },
      {
        path: 'addSound',
        name: 'addSound',
        component: () => import('@/views/sounds/list/add'),
        meta: {title: '添加音效', icon: 'list'}
      },
      //用户搜索音效关键词分析
      {
        path: 'userSearchLog',
        name: 'userSearchLog',
        component: () => import('@/views/sounds/userSearchLog/index'),
        meta: {title: '搜索关键词分析', icon: 'list'}
      },
      {
        path: 'findSound',
        name: 'findSound',
        component: () => import('@/views/sounds/findSound/index'),
        meta: {title: '找音效', icon: 'list'}
      },
      {
        path: 'answerFindSound',
        name: 'answerFindSound',
        component: () => import('@/views/sounds/findSound/answer'),
        meta: {title: '回复找音效', icon: 'list'}
      },
      //分类
      {
        path: 'category',
        name: 'category',
        component: () => import('@/views/sounds/category/index'),
        meta: {title: '分类列表', icon: 'product-add'}
      },
      
      {
        path: 'addCategory',
        name: 'addCategory',
        component: () => import('@/views/sounds/category/add'),
        meta: {title: '添加分类', icon: 'product-add'}
      },

      {
        path: 'updateCategory',
        name: 'updateCategory',
        component: () => import('@/views/sounds/category/update'),
        meta: {title: '修改分类', icon: 'product-add'}
      },
    ]
  },

  {
    path: '/sounds_v2',
    component: Layout,
    redirect: '/sounds_v2/menus',
    name: 'Soundsv2',
    meta: { title: '音效管理', icon: 'el-icon-s-help' },
    children: [
      {
        path: 'menus',
        name: 'menus',
        component: () => import('@/views/sounds_v2/menu/menus'),
        meta: { title: '音效分类', icon: 'table' }
      },
      {
        path: 'add_menus',
        name: 'add_menus',
        component: () => import('@/views/sounds_v2/menu/add'),
        meta: { title: '添加音效分类', icon: 'table' }
      },
      {
        path: 'edit_menu',
        name: 'edit_menu',
        component: () => import('@/views/sounds_v2/menu/edit'),
        meta: { title: '修改音效分类', icon: 'table' },
        hidden:true,
      },
      {
        path: 'table',
        name: 'Table',
        component: () => import('@/views/sounds_v2/index'),
        meta: { title: '音效列表', icon: 'table' }
      },
      // {
      //   path: 'upload',
      //   name: 'upload',
      //   component: () => import('@/views/sounds_v2/upload'),
      //   meta: { title: '上传新音效', icon: 'table' }
      // },
    ]
  },

  {
    path: '/app_setting',
    component: Layout,
    redirect: '/app_setting/update',
    name: 'appSeting',
    meta: { title: '软件设置', icon: 'el-icon-s-help' },
    children: [
      {
        path: 'update',
        name: 'app_setting_update',
        component: () => import('@/views/app/update'),
        meta: { title: '通用设置', icon: 'table' }
      },
    ]
  },


  //代理
  {
    path: '/agents',
    name: 'agents',
    component: Layout,
    redirect: '/agents/agentsList',
    meta: {title: '代理', icon: 'sounds'},
    children: [
      //代理列表
      {
        path: 'agentsList',
        name: 'agentsList',
        component: () => import('@/views/agents/list/index'),
        meta: {title: '代理列表', icon: 'list'}
      },
      {
        path: 'update',
        name: 'updateAgent',
        component: () => import('@/views/agents/list/update'),
        meta: {title: '修改音效', icon: 'list'}
      },
      {
        path: 'add',
        name: 'addAgent',
        component: () => import('@/views/agents/list/add'),
        meta: {title: '添加音效', icon: 'list'}
      },
    ]
  },

  //激活码类型
  {
    path: '/codetypes',
    name: 'codetypes',
    component: Layout,
    redirect: '/codetypes/list',
    meta: {title: '激活码类型', icon: 'sounds'},
    children: [
      //激活码类型列表
      {
        path: 'list',
        name: 'codetypeList',
        component: () => import('@/views/codetypes/list/index'),
        meta: {title: '列表', icon: 'list'}
      },
      {
        path: 'update',
        name: 'updateCodeType',
        component: () => import('@/views/codetypes/list/update'),
        meta: {title: '修改音效', icon: 'list'}
      },
      {
        path: 'add',
        name: 'addCodeType',
        component: () => import('@/views/codetypes/list/add'),
        meta: {title: '添加', icon: 'list'}
      },
    ]
  },

  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('@/views/wavesurfer.vue'),
  // },
]

const router = new VueRouter({
  routes
})

export default router
