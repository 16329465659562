import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:null,
    user:null,
  },
  mutations: {
    setToken(state,data){
      state.token = data
      window.sessionStorage.setItem("token",data)
    },
    login(state,data){
      state.user = data
      window.sessionStorage.setItem("user",JSON.stringify(data))
    },
    logout(state,data){
      state.user = null
      state.token = null
      window.sessionStorage.removeItem("token")
      window.sessionStorage.removeItem("user")
    },
  },
  getters:{
    getToken(state,getters){
      return state.token || window.sessionStorage.getItem("token")
    },
    getUser(state,getters){
      return state.user || JSON.parse( window.sessionStorage.getItem("user") )
    }
  },
  actions: {
  },
  modules: {
  }
})
